import { Component, OnInit, ViewChild, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/_services/dispo/order.service';
import { NoteService } from 'src/app/_services/dispo/note.service';
import { RentalService } from 'src/app/_services/cinema/rental.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit, OnChanges {

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['module_item_id']) {
      this.fetchData();
    }
  }

  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  directiveRef?: PerfectScrollbarDirective;
  closeResult: string = '';
  noteList: any;
  currentUser: any;

  @Input() view : any;
  @Input() module_id : any;
  @Input() module_item_id : any;
  @Input('ngModel') description: any
  //@ViewChild(PerfectScrollbarDirective, { static: true })

   /**
 * Constructor
 * @param Renderer2                 renderer
 */
    constructor(
      private renderer: Renderer2,
      private modalService: NgbModal,
      private noteSerivce: NoteService,
      private router: Router,
      private route: ActivatedRoute
    ) {}

 
  ngOnInit(): void {
    //this.fetchData();
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  onRouterOutletActivate(event) {
   this.fetchData();
  }

  convertToDate(unix) {
    const date = new Date(unix*1000);
    return date;
  }

  storeNote() {
    this.noteSerivce.store({module_id: this.module_id, module_item_id: this.module_item_id, description: this.description}).subscribe(
      data => {
        this.noteList = data;
        this.description = "";
      },
      err => {
        this.noteList = JSON.parse(err.error).message;
      }
    );
  }

  fetchData() {
    this.noteSerivce.getModuleNote(this.module_id, this.module_item_id).subscribe(
      data => {
        this.noteList = data;
      },
      err => {
        this.noteList = JSON.parse(err.error).message;
      }
    );
  }
}
